import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ImpersonateService } from './impersonate.service';

@Component({
  selector: 'app-impersonate-dialog',
  templateUrl: './impersonate-dialog.component.html',
  styleUrls: ['./impersonate-dialog.component.scss'],
  providers: [ImpersonateService],
})
export class ImpersonateDialogComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    selectedOrg: new FormControl('', [Validators.required]),
    selectedOrgUser: new FormControl(null, [Validators.required]),
    reason: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
    ]),
  });

  organizations = [];

  orgUsers = [];

  reason: string | null = null;
  impersonateSubscription: any;

  constructor(
    public config: DynamicDialogConfig,
    private impersonateService: ImpersonateService
  ) {}

  ngOnInit() {
    this.impersonateSubscription = this.impersonateService
      .getOrganizations()
      .subscribe((data: any) => {
        this.organizations = data;
        this.orgChanged();
      });
  }

  orgChanged() {
    //Get the organization users for the selected organization
    if (this.formGroup.get('selectedOrg')?.value) {
      this.impersonateService
        .getUsersForOrganization(this.formGroup.get('selectedOrg')!.value!)
        .subscribe((data: any) => {
          this.orgUsers = data;
        });
    }
  }

  doImpersonate() {
    //validate the form
    if (this.formGroup.invalid) return;
    let orgUser = this.formGroup.get('selectedOrgUser')?.value;
    if (!orgUser) return;

    this.impersonateService.impersonateUser(
      orgUser['uuid'],
      this.formGroup.get('reason')?.value ?? 'reason not provided',
      null,
      orgUser['user.name']
    );
  }

  handleEnter(keyEvent: any) {
    if (keyEvent.key === 'Enter') {
      this.doImpersonate();
    }
  }

  ngOnDestroy(): void {
    this.impersonateSubscription?.unsubscribe();
  }
}
