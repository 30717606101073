import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {LoadingService} from '../services/loading.service';
import {ToastService} from "../services/toast.service";
import {Router} from "@angular/router";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private router: Router,
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.get('skip')) {
      return next.handle(request);
    }
    let authenticatedRequest = undefined;
    if (localStorage.getItem('token')) {
      authenticatedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + localStorage.getItem('token')
        ),
      });
    }
    return next.handle(authenticatedRequest || request).pipe(
      catchError(async (error: HttpErrorResponse) => {
        this.loadingService.changeIsLoading(false);
        if (error.status === 400)
          this.toastService.addToast(error.error.detail, 'error', 'pi pi-exclamation-triangle');


        if (error.status === 401) {
          this.authService.logout(false);
          this.toastService.addToast(error.error.detail, 'warn', 'pi pi-exclamation-triangle');
          throw error;
        }
        if (error.status === 403) {
          this.toastService.addToast(error.error.detail, 'error', 'pi pi-exclamation-triangle');
          throw error;
        }
        if (error.status === 404) {
          this.toastService.addToast(error.error.detail, 'error', 'pi pi-exclamation-triangle');
          throw error;
        }
        if (error.status === 409) {
          this.toastService.addToast(error.error.detail, 'warn', 'pi pi-exclamation-triangle');
          throw error;
        }
        if (error.status === 500) {
          this.toastService.addToast('An error occurred, please contact support if this persists.',
            'error', 'pi pi-exclamation-triangle');
          throw error;
        }
        if (error.status === 422) {
          this.toastService.addToast(error.error.detail, 'warn', 'pi pi-exclamation-triangle');
          throw error;
        }

        if (error.status === 429) {
          let text = await error.error.text();
          this.toastService.addToast(JSON.parse(text).detail, 'warn', 'pi pi-exclamation-triangle');
          await this.router.navigate(['/liveview']);
          throw error;
        }
        if (error.status === 499) {
          await this.router.navigate(['/otp']);

        }
        if (error.status === 498) {
          await this.router.navigate(['/profile/setup-otp']);
        }
        throw error;
      })
    );
  }
}
