<div class="navbar">
  <div class="navbar-left">
    <div class="sidebar-button">
      <p-button
        (click)="menu.toggle($event)"
        class="p-button-sm p-button-rounded"
        i18n-pTooltip
        icon="pi pi-ellipsis-v"
        pRipple
        pTooltip="Menu"
      ></p-button>
      <p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
    </div>
    <div class="navbar-header">
      {{ title }}
    </div>
  </div>
  <div class="navbar-right">
    <div class="user-menu">
      <span class="org-header">
        <p><i class="pi pi-briefcase"></i> {{ userInfo?.current_org_name }}</p>
      </span>
      <div class="dropdown">
        <p-menu #dropdownMenu [model]="dropdownMenuOptions" [popup]="true" styleClass="profile-dropdown" appendTo="body"></p-menu>
        <p-button
          (onClick)="toggleDropdown($event)"
          class="dropdown-toggle user-header"
          id="dropdownMenuButton"
          type="button"
          icon="pi pi-angle-down"
          iconPos="right"
        >
          <i class="pi pi-user"></i>
          <p *ngIf="!oldUsername">{{ userInfo?.name }}</p>
          <p *ngIf="oldUsername">{{ oldUsername }}</p>
        </p-button>

      </div>
    </div>
  </div>
</div>
