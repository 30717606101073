import {Component} from "@angular/core";
import {PrimeNG} from "primeng/config";
import Material from "@primeng/themes/material"
import {definePreset, palette} from "@primeng/themes"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'vcap3-frontend';

  constructor(private primeng: PrimeNG) {
    const primaryColor = palette('#84b3e1')
    const MyPreset = definePreset(Material, {
      semantic: {
        primary: primaryColor,
      }
    });

    this.primeng.theme.set({
      preset: MyPreset,
      options: {
        cssLayer: {
          name: 'primeng',
          order: 'tailwind-base, primeng, tailwind-utilities'
        },
        darkModeSelector: false || 'none',
      }
    })
  }
}
