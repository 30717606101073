import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
    imports: [
        CommonModule,
        MenuModule,
        ButtonModule,
        PanelMenuModule,
        NgScrollbarModule,
        RouterLink,
    ],
})
export class SidebarModule {}
