<app-sidebar></app-sidebar>
<app-navbar></app-navbar>
<div class="main-panel">
  <router-outlet></router-outlet>
</div>
<div class="dialog-mainlayout">
  <p-dialog
    [blockScroll]="true"
    [closable]="false"
    [dismissableMask]="false"
    [draggable]="false"
    [focusOnShow]="true"
    [focusTrap]="true"
    [modal]="true"
    [resizable]="false"
    [showHeader]="false"
    [visible]="isLoading"
    [baseZIndex]="9999"
    styleClass="dialog-mainlayout"
  >
    <p-progress-spinner></p-progress-spinner>
  </p-dialog>
</div>
