import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { RippleModule } from 'primeng/ripple';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ImpersonateDialogComponent } from './impersonate-dialog/impersonate-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { SwitchOrgDialogComponent } from './switch-org-dialog/switch-org-dialog.component';
import { Select } from "primeng/select";
import {Button} from "primeng/button";
import {Tooltip} from "primeng/tooltip";
import {FloatLabel} from "primeng/floatlabel";
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [
    NavbarComponent,
    ImpersonateDialogComponent,
    SwitchOrgDialogComponent,
  ],
  exports: [NavbarComponent, ImpersonateDialogComponent],
  imports: [
    CommonModule,
    MenubarModule,
    RippleModule,
    MenuModule,
    TieredMenuModule,
    FormsModule,
    DividerModule,
    DropdownModule,
    InputTextModule,
    ReactiveFormsModule,
    Select,
    Button,
    Tooltip,
    FloatLabel,
    RouterLink,
  ],
})
export class NavbarModule {}
