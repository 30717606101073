import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {MainLayoutComponent} from './layouts/main/main-layout.component';
import {PublicModule} from './public/public.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SidebarModule} from './shared/sidebar/sidebar.module';
import {NavbarModule} from './shared/navbar/navbar.module';
import {AuthService} from './shared/services/auth.service';
import {AuthGuardService} from './shared/services/auth.guard.service';
import {OrgAdminGuardService} from './shared/services/orgadmin.guard.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {RequestInterceptor} from './shared/handlers/http.interceptor.handler';
import {FeatureGuardService} from './shared/services/feature.guard.service';
import {DialogModule} from 'primeng/dialog';
import {LoadingService} from './shared/services/loading.service';
import {MessageService} from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {SuperAdminGuardService} from './shared/services/superadmin.guard.service';
import {CookieService} from 'ngx-cookie-service';
import {CommonModule} from '@angular/common';
import {ToastService} from "./shared/services/toast.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MarkdownModule} from "ngx-markdown";

@NgModule({ declarations: [AppComponent, AuthLayoutComponent, MainLayoutComponent],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ProgressSpinnerModule,
        PublicModule,
        SidebarModule,
        NavbarModule,
        DialogModule,
        ToastModule,
        MarkdownModule.forRoot({})], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        AuthGuardService,
        AuthService,
        OrgAdminGuardService,
        SuperAdminGuardService,
        FeatureGuardService,
        LoadingService,
        MessageService,
        CookieService,
        ToastService,
        DynamicDialogConfig,
        DynamicDialogRef,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
